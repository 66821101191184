<template>
    <div class="user-content">
        <div class="user-info-title">我的网签合同</div>
        <div class="user-info-list">
            <el-table :data="data.tableData" style="width: 100%">
                <el-table-column prop="no" label="序号" width="180">
                </el-table-column>
                <el-table-column prop="title" label="名称" width="180">
                </el-table-column>
                <el-table-column prop="type" label="类型">
                </el-table-column>
                <el-table-column prop="time" label="签约时间">
                </el-table-column>
                <el-table-column prop="action" label="操作">
                </el-table-column>
            </el-table>
            <div class="user-info-list-page">
                <el-pagination background layout="prev, pager, next" :total="1">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue'

const data = reactive({
    tableData: [
        
    ]
})

</script>
<style lang="scss" scoped>
.user-content {
    padding: 20px;
    background-color: #fff;
}

.user-info-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

/**user info table  */
.user-info-list {}
.user-info-list-page {
    margin-top: 20px;
}
</style>
